import styled from "@emotion/styled";
import TariffInfo from "../elements/TariffInfo";
import NavigationButton from "../elements/NavigationButton";
import { useEffect, useRef } from "react";
import CurrentMeasurement from "./currentMeasurement/CurrentMeasurement";
import SpeedtestGuide from "./speedtestGuide/SpeedtestGuide";
import AdditionalInfo from "./additionalInfo/AdditionalInfo";
import History from "./history/History";
import { getAllRecords } from "../../../features/history/getAllRecords";
import { currentLangIs, defaultLanguage, nullOrValue } from "../../../helper/utils";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import t from "../../../helper/t";
import CommonContainer from "../elements/CommonContainer";
import WhiteContainer from "./WhiteContainer";
import NextBestOffer from "../../../features/nextBestOffer/NextBestOffer";
import { NboCase } from "../../../features/nextBestOffer/NextBestOfferUtils";
import { showLastNboTeaser } from "../../../features/nextBestOffer/nextBestOfferSlice";
import { ResultTabKey } from "../../../features/pageManager/pageState";
import { updateResultPageTab } from "../../../features/pageManager/pageManagerSlice";

const RootDiv = styled.div(({ theme }) => ([
  {
    flexGrow: 10,
  }
]));

const TariffInfoContainer = styled.div([
  {
    width: "100%",
  }
]);

const ContainerDiv = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",
    paddingTop: 14,
    paddingLeft: 20,
    paddingRight: 20,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      {
        paddingLeft: 16,
        paddingRight: 16,
      }
    ],
  }
]));

const HeaderTextDiv = styled.div(({ theme }) => ([
  theme.splus3.foreground.monochrome600,
  theme.splus3.typography.h2,
  {
    marginBottom: 48,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      theme.splus3.typography.font24,
      {
        marginBottom: 20,
      }
    ],
  }
]));

const TabContainer = styled.div(({ theme }) => ([
  {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridTemplateRows: "1fr",
    alignSelf: "center",
    columnGap: 8,
    maxWidth: 800,
    width: "100%",
    overflowX: "initial",


    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      {
        columnGap: 12,
        paddingLeft: 8,
        paddingRight: 8,
        width: "calc(100% - 16px)",
        overflowX: "auto",
        scrollbarWidth: "none",
        "::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
      }
    ],
  }
]));

const TabContent = styled.div(({ theme }) => ([
  {
    display: "flex",
    flexDirection: "column",
    rowGap: 8,

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      {
        rowGap: 4,
      }
    ],
  },
]));

interface ButtonContentProps {
  isSelected: boolean;
}

const ButtonContent = styled.div<ButtonContentProps>(({ theme, isSelected }) => ([
  theme.splus3.foreground.monochrome600,
  isSelected ? theme.splus3.typography.defaultBold : theme.splus3.typography.default,
  {
    textAlign: "center",
    lineHeight: "26px",
    whiteSpace: "nowrap",

    /* for mobile */
    [theme.splus3.breakpoints.mobile]: [
      isSelected ? theme.splus3.typography.smallBold : theme.splus3.typography.font12,
      isSelected ?
        {
          marginLeft: 12,
          marginRight: 12,
        } : {},
      {
        lineHeight: "26px",
      }
    ],
  },
]));

const UnderlineDiv = styled.div<ButtonContentProps>(({ theme, isSelected }) => ([
  theme.splus3.background.vodafoneRed,
  {
    height: 4,
    borderTopLeftRadius: 2,
    borderTopRightRadius: 2,
    opacity: isSelected ? 1 : 0,
  },
]));

interface ResultPageProps {
}

const ResultPage: React.FC<ResultPageProps> = () => {
  const dispatch = useAppDispatch();

  const historyState = useAppSelector(state => state.history);
  const resultPageState = useAppSelector(state => state.pageManager).resultState;
  const sortedRecords = getAllRecords(historyState);
  const lastRecord = sortedRecords[0];
  const bookedDownloadSpeed = nullOrValue(lastRecord?.prepareResult.init.modem.bookedDownloadSpeedMax);
  const bookedUploadSpeed = nullOrValue(lastRecord?.prepareResult.init.modem.bookedUploadSpeedMax);
  const nboState = useAppSelector(state => state.nextBestOffer);

  const tabButtonList = [
    { caption: t("Aktuelle Messung"), tabKey: ResultTabKey.CurrentMesasurement, ref: useRef<HTMLDivElement>(null) },
    { caption: t("Speedtest Guide"), tabKey: ResultTabKey.SpeedtestGuide, ref: useRef<HTMLDivElement>(null),dataCy: "guide-button" },
    { caption: t("Zusätzliche Infos"), tabKey: ResultTabKey.AdditionalInfo, ref: useRef<HTMLDivElement>(null) },
    { caption: t("Frühere Ergebnisse"), tabKey: ResultTabKey.History, ref: useRef<HTMLDivElement>(null), dataCy: "history-button" },
  ];

  useEffect(() => {
    const tab = tabButtonList.find(t => t.tabKey === resultPageState.currentTab);
    tab?.ref.current?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" });
    dispatch(showLastNboTeaser());
  }, [resultPageState.currentTab]);

  const getTitle = (): string => {
    switch (resultPageState.currentTab) {
      case ResultTabKey.CurrentMesasurement: return t("Dein Ergebnis");
      case ResultTabKey.SpeedtestGuide: return t("Dein Speedtest Guide");
      case ResultTabKey.AdditionalInfo: return t("Zusätzliche Infos");
      case ResultTabKey.History: return t("Frühere Ergebnisse");
    }
  }

  const navButton = (name: string, tab: ResultTabKey, dataCy?: string) => {
    const isSelected = tab === resultPageState.currentTab;

    return (
      <NavigationButton dataCy={dataCy} onClick={() => dispatch(updateResultPageTab(tab))} isEnabled={!isSelected}>
        <TabContent>
          <ButtonContent isSelected={isSelected}>
            {name}
          </ButtonContent>
          <UnderlineDiv isSelected={isSelected} />
        </TabContent>
      </NavigationButton>
    );
  }

  const content = () => {
    switch (resultPageState.currentTab) {
      case ResultTabKey.CurrentMesasurement: return <CurrentMeasurement showInfo={true} />;
      case ResultTabKey.SpeedtestGuide: return <SpeedtestGuide />;
      case ResultTabKey.AdditionalInfo: return <AdditionalInfo />;
      case ResultTabKey.History: return <History />;
    }
  }

  const bottomWidget = () => {
    if (currentLangIs(defaultLanguage) &&
      (resultPageState.currentTab === ResultTabKey.CurrentMesasurement || resultPageState.currentTab === ResultTabKey.AdditionalInfo) &&
      nboState.nboCase !== NboCase.None && !nboState.hidden) {
      return <NextBestOffer />
    }

    return null;
  }

  return (
    <RootDiv>
      <WhiteContainer>
        <TariffInfoContainer>
          <TariffInfo fading={false} bookedDownloadSpeed={bookedDownloadSpeed} bookedUploadSpeed={bookedUploadSpeed} />
        </TariffInfoContainer>

        <CommonContainer>
          <ContainerDiv>
            <HeaderTextDiv>
              {getTitle()}
            </HeaderTextDiv>
          </ContainerDiv>

          <TabContainer>
            {tabButtonList.map((tab, index) =>
              <div key={index} ref={tab.ref}>
                {navButton(tab.caption, tab.tabKey, tab.dataCy)}
              </div>
            )}
          </TabContainer>
        </CommonContainer>
      </WhiteContainer>

      {content()}
      {bottomWidget()}
    </RootDiv>
  );
}

export default ResultPage;
