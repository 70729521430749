import styled from "@emotion/styled";

const RootDiv = styled.div([
    {
        display: "grid",
        gridTemplateRows: "1fr",
    }
]);

interface BorderAreaDivProps {
    isActive: boolean;
    width: number;
    height: number;
}

const BorderAreaDiv = styled.div<BorderAreaDivProps>(({ theme, isActive, width, height }) => ([
    isActive ? theme.splus3.background.vodafoneGradient : theme.splus3.background.white,
    {
        gridColumn: 1,
        gridRow: 1,

        width,
        height,
        padding: 2,
        borderRadius: 4,
    }
]));

const MainAreaDiv = styled.div([
    props => props.theme.splus3.foreground.primary,
    props => props.theme.splus3.background.white,
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: 4,

        height: "100%",
    }
]);

const CaptionTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.defaultBold,
    {
        marginTop: 22,

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.smallBold,
            {
                marginTop: 16,
            }
        ],
    }
]));

const ValueTextDiv = styled.div(({ theme }) => ([
    theme.splus3.typography.font15,
    {
        marginTop: 6,
        paddingLeft: 20,
        paddingRight: 20,
        height: 36,

        overflow: "hidden",
        textOverflow: "ellipsis",
        textAlign: "center",
        whiteSpace: "pre-wrap",

        /* for mobile */
        [theme.splus3.breakpoints.mobile]: [
            theme.splus3.typography.font12,
            {
                marginTop: 0,
                paddingLeft: 10,
                paddingRight: 10,
            }
        ],
    }
]));

const ImageAreaDiv = styled.div([
    {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        gridColumn: 1,
        gridRow: 1,

        marginLeft: "auto",
        marginRight: "auto",
    }
]);

interface MeasurementItemProps {
    caption: string;
    value?: string | null;
    isActive: boolean;
    width: number;
    height: number;
}

const MeasurementItem: React.FC<MeasurementItemProps> = ({ caption, value, isActive, width, height, children }) => {
    return (
        <RootDiv>
            <BorderAreaDiv isActive={isActive} width={width} height={height}>
                <MainAreaDiv>
                    <CaptionTextDiv>
                        {caption}
                    </CaptionTextDiv>
                    <ValueTextDiv>
                        {value}
                    </ValueTextDiv>
                </MainAreaDiv>
            </BorderAreaDiv>
            <ImageAreaDiv>
                {children}
            </ImageAreaDiv>
        </RootDiv>
    );
}

export default MeasurementItem;
